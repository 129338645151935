body {
  background: #fff;
  color: #000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

h1 {
  font-size: 40px !important;
  line-height: 60px !important;
}

h1,
h2 {
  font-weight: 700 !important;
}

h2 {
  font-size: 32px !important;
  line-height: 48px !important;
}

h3 {
  font-size: 24px !important;
  line-height: 36px !important;
}

h3,
h4 {
  font-weight: 700 !important;
}

h4 {
  font-size: 20px !important;
  line-height: 30px !important;
}

h5 {
  font-size: 16px !important;
}

h5,
h6 {
  line-height: 24px !important;
  font-weight: 700 !important;
}

h6 {
  font-size: calc(16px) !important;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
  color: #000 !important;
}

a:hover,
a[rel~="nofollow"] {
  text-decoration: underline !important;
}

a[rel~="nofollow"] {
  color: #0452a5 !important;
}

a[rel~="nofollow"]:hover {
  text-decoration: none !important;
}

.visible {
  display: block !important;
}

.hidden {
  display: none !important;
}

.page {
  width: 100% !important;
}

.container {
  position: relative !important;
  width: 90% !important;
  max-width: 1024px !important;
  margin: 0 auto !important;
}

.header {
  color: #000 !important;
  padding: 16px 0 !important;
}

.header .container .title {
  font-size: 24px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  margin: 0 !important;
}

.translations-list-container {
  color: #000 !important;
  padding-bottom: 8px !important;
  margin: 0 0 16px !important;
}

.translations-list-container .translations-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.translations-list-container .translations-list .translations-list-item {
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 8px 8px 0 !important;
  color: #fff !important;
}

.translations-list-container .translations-list .translations-list-item a {
  display: inline-block !important;
  padding: 4px 8px !important;
}

.translations-list-container
  .translations-list
  .translations-list-item
  a.active {
  color: #fff !important;
  background: #334055 !important;
}

.translations-content-container {
  padding-top: 8px !important;
  border-top: 1px solid #eee !important;
}

.footer {
  border-top: 1px solid #eee !important;
  margin: 32px 0 0 !important;
  padding: 16px 0 !important;
}
